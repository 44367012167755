.container {
  /* background-color: yellow; */
  max-width: 1200px;
  margin: 0 auto 0 auto;
  background-image: url('../images/backgroundIMG1.jpg');
  background-repeat: no-repeat;
}

.about {
  display: flex;
  margin-top: 40px;
  /* filter:alpha(opacity=50); opacity:0.5; */
}

.left-block {
  width: 60%;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.text-block {
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* background-color: cornsilk; */
}

.text-hello {
  font-size: 1.25rem;
}

.text-intro {
  font-size: 2.5rem;
}

.text-role {
  font-size: 1.5rem;
}

.resume {
  background-color: inherit;
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  color: #fff;
  border: 1px solid #fff;
}

.resume:hover {
  background-color: #fff;
  color: #1e1e1e;
  transform: scale(1.1);
}

.links a i {
  color: #fff;
}

.links a i:hover {
  transform: scale(1.2);
}

.right-block {
  width: 40%;
}

.right-block img {
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 960px) {
  .left-block,
  .right-block {
    width: 50%;
  }
}

@media screen and (max-width: 740px) {
  .container {
    height: 100vh;
  }
  .about {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: orange; */
    height: 100%;
  }
  .left-block {
    width: 100%;
    justify-content: center;
    /* background-color: bisque; */
  }

  .text-hello {
    font-size: 1rem;
  }

  .text-intro {
    font-size: 2rem;
  }

  .text-role {
    font-size: 1.2rem;
  }

  .right-block {
    /* width:100%;
        justify-content: center;
        align-items: center; */
    display: none;
  }
}

/**modify scroll bar*/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: burlywood;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
